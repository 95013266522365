import { Typography, Grid, Box, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import Page from './Page';

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: 'Source Serif Pro',
    cursor: 'pointer',
  },
}));

const Film = () => {
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState('');
  const [film, setFilm] = useState('');
  const [video, setVideo] = useState('');
  const [session, setSession] = useState('');

  const setItemAndReset = (selectedItem) => {
    setFilm('');
    setVideo('');
    setSession('');
    setSelectedItem(selectedItem);
  };

  const navbar = [
    {
      text: 'ταινίες',
      onClick: setItemAndReset,
    },
    {
      text: 'βίντεοκλιπ',
      onClick: setItemAndReset,
    },
    {
      text: 'σέσσιονς',
      onClick: setItemAndReset,
    },
  ];
  const films = [
    {
      gr: 'Όλα Χρειάζονται ένα Ψαλιδάκι για τα Νύχια',
      en: 'Everyone Needs a Pair of Nail Scissors',
    },
    {
      gr: 'Αδράνεια',
      en: 'Inertia',
    },
    {
      gr: 'Τι μου Λείπει περισσότερο Πάνω σου',
      en: 'What I Miss most Αbout you',
    },

    {
      gr: 'Άλλη μία νύχτα',
      en: 'Just another night',
    },
  ];
  const videos = [
    {
      song: 'ΟΛΑ ΜΕΣΑ',
      artist: 'Δράμα Κωμικού',
    },
    {
      song: 'Palermo',
      artist: 'Aeon x Topo',
    },
    {
      song: 'Νίκα τον Ηρώδη',
      artist: 'Δράμα Κωμικού',
    },
  ];

  const sessions = [
    {
      sess: 'Kalifornia (Live from Dorothy΄s)',
      artist: 'Μήτσος Γκουλιμάρης',
    },
  ];

  return (
    <Page
      title="stork films"
      canonical="https://www.storkcollective.com/films"
      navbar={navbar}
      selectedItem={selectedItem}
      fontFamily="Source Serif Pro"
    >
      <Grid container item spacing={3}>
        {selectedItem === 'ταινίες' &&
          film === '' &&
          films.map((film) => (
            <Grid item key={film.en} xs={12} sm={6}>
              <Box textAlign="justify" justifyContent="space-evenly" onClick={() => setFilm(film.en)}>
                <Typography className={classes.title} variant="body1">
                  {film.gr}
                </Typography>
                {/* eslint-disable-next-line */}
                <Typography className={classes.title} variant="body1">
                  {film.en}
                </Typography>
              </Box>
            </Grid>
          ))}
        {selectedItem === 'βίντεοκλιπ' &&
          video === '' &&
          videos.map((video) => (
            <Grid item key={video.song} xs={12} sm={6}>
              <Box textAlign="justify" onClick={() => setVideo(video.song)}>
                <Typography className={classes.title} variant="body1">
                  {video.song}
                </Typography>
                <Typography className={classes.title} variant="body1">
                  {video.artist}
                </Typography>
              </Box>
            </Grid>
          ))}
        {selectedItem === 'σέσσιονς' &&
          session === '' &&
          sessions.map((session) => (
            <Grid item key={session.sess} xs={12} sm={6}>
              <Box textAlign="justify" onClick={() => setSession(session.sess)}>
                <Typography className={classes.title} variant="body1">
                  {session.sess}
                </Typography>
                <Typography className={classes.title} variant="body1">
                  {session.artist}
                </Typography>
              </Box>
            </Grid>
          ))}
      </Grid>

      {/* Films */}

      <Box>
        {selectedItem === 'ταινίες' && film === 'Just another night' && (
          <div style={{ marginTop: 24, padding: '56.25% 0 0 0', position: 'relative' }}>
            <iframe
              title="Just another night"
              src="https://www.youtube.com/embed/qlXGQWrkaFg"
              style={{ position: 'absolute', top: 0, left: 90, width: '90%', height: '90%' }}
              frameBorder="0"
              allow="autoplay; fullscreen; clipboard-write; gyroscope; encrypted-media; accelerometer; picture-in-picture"
            />
          </div>
          
        )}
        {selectedItem === 'ταινίες' && film === 'What I Miss most Αbout you' && (
          <>
            <div style={{ marginTop: 24, padding: '56.25% 0 0 0', position: 'relative' }}>
              <iframe
                title="What I Miss most Αbout you"
                src="https://player.vimeo.com/video/428279125"
                style={{ position: 'absolute', top: 0, left: 90, width: '90%', height: '90%' }}
                frameBorder="0"
                allow="autoplay; fullscreen"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </>
        )}
        {selectedItem === 'ταινίες' && film === 'Inertia' && (
          <>
            <div style={{ marginTop: 24, padding: '56.25% 0 0 0', position: 'relative' }}>
              <iframe
                title="Αδράνεια"
                src="https://www.youtube.com/embed/vJqQF8FwGig"
                style={{ position: 'absolute', top: 0, left: 90, width: '90%', height: '90%' }}
                frameBorder="0"
                allow="autoplay; fullscreen; clipboard-write; gyroscope; encrypted-media; accelerometer; picture-in-picture"
              />
            </div>
          </>
        )}
          {selectedItem === 'ταινίες' && film === 'Everyone Needs a Pair of Nail Scissors' && (
          <>
            <div style={{ marginTop: 24, padding: '56.25% 0 0 0', position: 'relative' }}>
              <iframe
                title="Ψαλιδάκι"
                src="https://www.youtube.com/embed/6_ws9gEIYmY"
                style={{ position: 'absolute', top: 0, left: 90, width: '90%', height: '90%' }}
                frameBorder="0"
                allow="autoplay; fullscreen; clipboard-write; gyroscope; encrypted-media; accelerometer; picture-in-picture"
              />
            </div>
          </>
        )}

        {/*{selectedItem === 'ταινίες' && film === 'Leave the Best for Last' && (
          <>
          <Typography>coming soon</Typography>
            <div style={{ marginTop:24, padding: '56.25% 0 0 0', position: 'relative' }}>
              {/*<iframe
                title="Leave the Best for the End"
                src="https://player.vimeo.com/video/428279125"
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                frameBorder="0"
                allow="autoplay; fullscreen"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </>
        )}*/}
        {/* Music Videos */}

        {selectedItem === 'βίντεοκλιπ' && video === 'Νίκα τον Ηρώδη' && (
          <div style={{ marginTop: 24, padding: '56.25% 0 0 0', position: 'relative' }}>
            <iframe
              title="Νίκα τον Ηρώδη"
              src="https://www.youtube.com/embed/Kct3wyd5BI0"
              style={{ position: 'absolute', top: 0, left: 90, width: '90%', height: '90%'}}
              frameBorder="0"
              allow="autoplay; fullscreen; clipboard-write; gyroscope; encrypted-media; accelerometer; picture-in-picture"
            />
          </div>
        )}
        {selectedItem === 'βίντεοκλιπ' && video === 'Palermo' && (
          <div style={{ marginTop: 24, padding: '56.25% 0 0 0', position: 'relative' }}>
            <iframe
              title="Palermo"
              src="https://www.youtube.com/embed/4c_y-hGfUvg"
              style={{ position: 'absolute', top: 0, left: 90, width: '90%', height: '90%' }}
              frameBorder="0"
              allow="autoplay; fullscreen; clipboard-write; gyroscope; encrypted-media; accelerometer; picture-in-picture"
            />
          </div>
        )}
{selectedItem === 'βίντεοκλιπ' && video === 'ΟΛΑ ΜΕΣΑ' && (
          <div style={{ marginTop: 24, padding: '56.25% 0 0 0', position: 'relative' }}>
            <iframe
              title="ΟΛΑ ΜΕΣΑ"
              src="https://www.youtube.com/embed/5bDOTRMt3nk"
              style={{ position: 'absolute', top: 0, left: 90, width: '90%', height: '90%' }}
              frameBorder="0"
              allow="autoplay; fullscreen; clipboard-write; gyroscope; encrypted-media; accelerometer; picture-in-picture"
            />
          </div>
        )}
        {/* Sessions */}

        {selectedItem === 'σέσσιονς' && session === 'Kalifornia (Live from Dorothy΄s)' && (
          <div style={{ marginTop: 24, padding: '56.25% 0 0 0', position: 'relative' }}>
            <iframe
              title="Kalifornia (Live from Dorothy's)"
              src="https://www.youtube.com/embed/eVzqBhA6zXg"
              style={{position: 'absolute', top: 0, left: 90, width: '90%', height: '90%'}}
              frameBorder="0"
              allow="autoplay; fullscreen; clipboard-write; gyroscope; encrypted-media; accelerometer; picture-in-picture"
            />
          </div>
        )}
      </Box>
    </Page>
  );
};

export default Film;
