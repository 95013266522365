import { Typography, Box, Link, useMediaQuery } from '@material-ui/core';
import React from 'react';
import Page from './Page';

const Contact = () => {
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  return (
    <Page title="stork contact" canonical="https://www.storkcollective.com/contact" fontFamily="Courier New">
      <Box display="flex" justifyContent="space-between">
        {!mobile && (
          <Box width="35%" textAlign="justify">
            <Typography style={{ marginLeft: 0 }}>
              Είμαστε μια ομάδα ατόμων που δημιουργούν και/ή καταναλώνουν τέχνη.
              Λειτουργούμε αυτοοργανωμένα και πιστεύουμε στην ευθεία υποστήριξη των καλλιτεχνών. Βλέπουμε την τέχνη σαν τρόπο έκφρασης και όχι σαν εμπόρευμα. Στόχος μας είναι να δημιουργηθεί μία κοινότητα η οποία θα συζητάει, θα αλληλοβοηθιέται και θα εκφράζεται δημιουργικά και συνεργατικά. Το σχέδιο αυτό είναι σε καλό δρόμο.
<br />
            </Typography>
          </Box>
        )}
        <Box width={mobile ? '100%' : '23.9%'} textAlign="justify">
          vimeo:{' '}
          <Link href="https://vimeo.com/storkfilmsgr/" target="_blank" style={{ color: 'hsl(0,0%,55%' }}>
            storkfilmsgr
          </Link>
          <br />
          twitch:{' '}
          <Link href="https://www.twitch.tv/storkcollective" target="_blank" style={{ color: 'hsl(0,0%,55%' }}>
            StorkCollective
          </Link>
          <br />
          spotify:{' '}
          <Link
            href="https://open.spotify.com/user/140xzuboa22y8k626n78vrybh"
            target="_blank"
            style={{ color: 'hsl(0,0%,55%' }}
          >
            stork collective
          </Link>
          <br />
          youtube:{' '}
          <Link
            href="https://www.youtube.com/channel/UCLpdpIPh5RfIdRRoxEQvwjw?view_as=subscriber"
            target="_blank"
            style={{ color: 'hsl(0,0%,55%' }}
          >
            Stork Collective
          </Link>
          <br />
          facebook:{' '}
          <Link href="https://www.facebook.com/storkcollective" target="_blank" style={{ color: 'hsl(0,0%,55%' }}>
            Stork Collective
          </Link>
          <br />
          instagram:{' '}
          <Link href="https://www.instagram.com/storkcollective/" target="_blank" style={{ color: 'hsl(0,0%,55%' }}>
            @storkcollective
          </Link>
          <br />
          email:{' '}
          <Link href="mailto:storkcollective@gmail.com" style={{ color: 'hsl(0,0%,55%' }}>
            storkcollective@gmail.com
          </Link>
          <br />
        </Box>
      </Box>
    </Page>
  );
};

export default Contact;
