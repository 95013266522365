import { Box, Typography, Grid, Button} from '@material-ui/core';
import React, { useState } from 'react';
import Page from './Page';

const Book = () => {
  const [selectedItem, setSelectedItem] = useState('');
  const [page, setPage] = useState(1);

  const handleNavbarClick = (item) => {
    setPage(1);
    setSelectedItem(item);
  };

  const navbar = [
    {
      text: 'Ημερολόγιο ΄22',
      imgFolder: 'hmero',
      pages: 3,
      onClick: handleNavbarClick,
    },
    {
      text: 'Σημειωματάριο',
      imgFolder: 'notebook',
      pages: 3,
      onClick: handleNavbarClick,
    },
    {
      text: 'Άλλη μια Νύχτα // το σενάριο',
      imgFolder: 'night',
      pages: 3,
      onClick: handleNavbarClick,
    }

  ];

  return (
    <Page
      title="stork books"
      canonical="https://www.storkcollective.com/books"
      navbar={navbar}
      selectedItem={selectedItem}
      fontFamily="EB Garamond"
    >
      <Grid container spacing={4} justify="flex-end">
        <Grid item xs={9} >
          {navbar.map(
            (book) =>
              selectedItem === book.text && (
                <Box key={book.text} >
                  <img
                    alt="Book Page"
                    src={require(`../../src/img/${book.imgFolder}/${book.imgFolder}${page}.jpg`).default}
                    width="100%"
                  />
                  
                  <Box display="flex" justifyContent="space-around" mt={3}>
                    <Button
                      disabled={page <= 1}
                      onClick={() => setPage(page - 1)}
                      variant="text"
                      disableRipple="true"
                      disableElevation="true"
                      size="small"
                    >
                      <u> Previous </u>
                    </Button>
                    <Typography>{page}/3</Typography>
                    <Button
                      disabled={page >= book.pages}
                      onClick={() => setPage(page + 1)}
                      variant="text"
                      disableRipple="false"
                      disableElevation="true"
                      size="small"
                    >
                      <u> Next </u>
                    </Button>
                  </Box>
                 
                </Box>
              ),
          )}
        </Grid>
      </Grid>
{/* 
      <Grid container spacing={4} justify="flex-end">
        <Grid item xs={9} >
          {navbar.map(
            (book) =>
              selectedItem === 'Ημερολόγιο ΄22 (αφίσα)' && (
                <Box key={book.title} >
                  <img
                    alt="Book Page"
                    src={require(`../../src/img/poster22/poster22 web.jpg`).default}
                    width="70%"
                  />
                  <Typography>Η εικόνα με καλή ανάλυση βρίσκεται εδώ</Typography>
                  </Box>
              ),
          )}
        </Grid>
      </Grid> */}

    </Page>
  );
};

export default Book;
