import React from 'react';
import { Helmet } from 'react-helmet';

const Home = () => {
  return (
    <Helmet>
      <title>stork collective</title>
      <link rel="canonical" href="https://www.storkcollective.com" />
    </Helmet>
  );
};

export default Home;
