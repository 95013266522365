import { Typography, Grid, Box, Link, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import Page from './Page';

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: 'Prata',
  },
}));

const Record = () => {
  const classes = useStyles();
  // const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [selectedItem, setSelectedItem] = useState('');
  // const [single, setSingle] = useState('');
  const [album, setAlbum] = useState('');
  const [sess, setSess] = useState('');

  const setItemAndReset = (selectedItem) => {
    // setSingle('');
    setAlbum('');
    setSess('');
    setSelectedItem(selectedItem);
  };

  const navbar = [
    // {
    //   text: 'singles',
    //   onClick: setItemAndReset,
    // },
    {
      text: 'δίσκοι',
      onClick: setItemAndReset,
    },
    {
      text: 'σέσσιονς',
      onClick: setItemAndReset,
    },
    {
      text: 'λίστες',
      onClick: setItemAndReset,
    },
  ];

  // const singles = [
  //   {
  //     en: 'Sickbeat',
  //     creator: 'Yannis Tsoutsounis',
  //   },
  // ];
  const albums = [
    {
      en: 'Dark Nebulae (EP)',
      creator: 'Abel Lives',
    },
    {
      en: 'dUCEd1819 (EP)',
      creator: 'pauli',
    },
    // {
    //   en: 'flock (EP)',
    //   creator: '.mue',
    // },
    {
      en: 'Τσιμέντο (EP)',
      creator: 'Γιάννης Τσουτσούνης',
    },
    {
      en: '10 Songs About (LP)',
      creator: 'Μήτσος Γκουλιμάρης',
    },

  ];
  const sessions = [
    {
      sess: 'Kalifornia (Live from Dorothy΄s)',
      artist: 'Μήτσος Γκουλιμάρης',
    },
  ];

  const playlists = [
  {
      name: '// Jul. 2021 \\\\',
      iframe: (
        <iframe
          title="// Jul. 2021 \\"
          src="https://open.spotify.com/embed/playlist/5SUwF2kG3ymq9O0524oiyG"
        />
      ),
    },
  ];

  return (
    <Page
      title="stork records"
      canonical="https://www.storkcollective.com/records"
      navbar={navbar}
      selectedItem={selectedItem}
      fontFamily="Prata"
    >
      <Grid container item spacing={3}>
        {/* {selectedItem === 'singles' &&
          single === '' &&
          singles.map((single) => (
            <Grid key={single.en} item xs={12} sm={6}>
              <Box
                textAlign="justify"
                style={{ cursor: 'pointer' }}
                justifyContent="space-evenly"
                onClick={() => setSingle(single.en)}
              >
                <Typography className={classes.title} variant="body1">
                  {single.en}
                </Typography>
                <Typography className={classes.title} variant="body1">
                  {single.creator}
                </Typography>
              </Box>
            </Grid>
          ))} */}
        {selectedItem === 'δίσκοι' && (
          <Typography align="right">
            Πιστεύουμε στην ευθεία υποστήριξη των καλλιτεχνών μέσω ελεύθερης συνεισφοράς. <br />
Η στήριξη από το bandcamp πηγαίνει ολόκληρη στους μουσικούς.
            <br /><br />
          </Typography>
        )}

        {selectedItem === 'δίσκοι' &&
          album === '' &&
          albums.map((album) => (
            <Grid key={album.en} item xs={12} sm={6}>
              <Box
                textAlign="justify"
                style={{ cursor: 'pointer' }}
                justifyContent="space-evenly"
                onClick={() => setAlbum(album.en)}
              >
                <Typography className={classes.title} variant="body1">
                  {album.en}
                </Typography>
                <Typography className={classes.title} variant="body1">
                  {album.creator}
                </Typography>
              </Box>
            </Grid>

          ))}
        {selectedItem === 'σέσσιονς' &&
          sess === '' &&
          sessions.map((session) => (
            <Grid key={session.sess} item xs={12} sm={6}>
              <Box
                textAlign="justify"
                style={{ cursor: 'pointer' }}
                justifyContent="space-evenly"
                onClick={() => setSess(session.sess)}
              >
                <Typography className={classes.title} variant="body1">
                  {session.sess}
                </Typography>
                <Typography className={classes.title} variant="body1">
                  {session.artist}
                </Typography>
              </Box>
            </Grid>
          ))}

        {selectedItem === 'λίστες' &&
          playlists.map((playlist) => (
            <Grid key={playlist.name} item>
              <Link href="https://open.spotify.com/user/140xzuboa22y8k626n78vrybh?si=97b66ed4f1d44661" target="_blank" style={{ color: 'hsl(0,0%,40%' }}>
              spotify
              </Link><br></br>
              <Link href="https://www.youtube.com/channel/UCLpdpIPh5RfIdRRoxEQvwjw/playlists" target="_blank" style={{ color: 'hsl(0,0%,40%' }}>
              youtube
              </Link>
            </Grid>
          ))}
      </Grid>

      <Box>
        {/* Singles */}
        {/* {selectedItem === 'singles' && single === 'Sickbeat' && (
          <>
            <div style={{ marginTop: 24, padding: '56.25% 0 0 0', position: 'relative' }}>
              <iframe
                title="1. Long Gone"
                src="https://www.youtube.com/embed/kPB2HYJsc64"
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                frameBorder="0"
                allow="autoplay; fullscreen; clipboard-write; gyroscope; 
            encrypted-media; accelerometer; picture-in-picture"
              />
            </div>
            <Typography>more coming soon</Typography>
          </>
        )} */}

        {/* Albums */}
        {selectedItem === 'δίσκοι' && album === 'Dark Nebulae (EP)' && (
          <>
            <div style={{ marginTop: 24, marginLeft: 145, padding: '0 0 0 10%', position: 'relative' }}>
              <iframe
                title="Tsimento"
                src="https://bandcamp.com/EmbeddedPlayer/album=2616020788/size=large/bgcol=333333/linkcol=2ebd35/transparent=true/"
                style={{ border: 0, width: '400px', height: '580px' }}
                seamless>
                <a href="https://abellives.bandcamp.com/album/dark-nebulae">Dark Nebulae by Abel Lives</a>
              </iframe>
            </div>
          </>
        )}

        {selectedItem === 'δίσκοι' && album === '10 Songs About (LP)' && (
          <>
            <div style={{ marginTop: 24, padding: '56.25% 0 0 0', position: 'relative' }}>
              <iframe
                title="1. Long Gone"
                src="https://www.youtube.com/embed/kPB2HYJsc64"
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                frameBorder="0"
                allow="autoplay; fullscreen; clipboard-write; gyroscope; 
            encrypted-media; accelerometer; picture-in-picture"
              />
            </div>
            <Typography>more coming soon</Typography>
          </>
        )}
        {/* 
        {selectedItem === 'albums' && album === 'flock (EP)' && <Typography>coming very soon</Typography>}
         */}
        {selectedItem === 'δίσκοι' && album === 'Τσιμέντο (EP)' && (
          <>
            <div style={{ marginTop: 24, marginLeft: 145, padding: '0 0 0 10%', position: 'relative' }}>
              <iframe
                title="Tsimento"
                src="https://bandcamp.com/EmbeddedPlayer/album=1530865056/size=large/bgcol=333333/linkcol=e99708/transparent=true/"
                style={{ border: 0, width: '400px', height: '680px' }}
                seamless>
                <a href="https://tsoutsounis.bandcamp.com/album/-">Τσιμέντο by Yannis Tsoutsounis</a>
              </iframe>
            </div>
          </>
        )}
        {selectedItem === 'δίσκοι' && album === 'dUCEd1819 (EP)' && (
          <>
            <div style={{ marginTop: 24, marginLeft: 145, padding: '0 0 0 10%', position: 'relative' }}>
              <iframe
                title="dUCEd1819 (EP)"
                src="https://bandcamp.com/EmbeddedPlayer/album=3291134525/size=large/bgcol=333333/linkcol=e99708/transparent=true/"
                style={{ border: 0, width: '400px', height: '640px' }}
                seamless>
                <a href="https://paulidirtysounds.bandcamp.com/album/duced1819">dUCEd1819 by pauli</a>
              </iframe>
            </div>
          </>
        )}

        {/* Sessions */}

        {selectedItem === 'σέσσιονς' && sess === 'Kalifornia (Live from Dorothy΄s)' && (
          <div style={{ marginTop: 24, padding: '56.25% 0 0 0', position: 'relative' }}>
            <iframe
              title="Kalifornia (Live from Dorothy's)"
              src="https://www.youtube.com/embed/eVzqBhA6zXg"
              style={{ position: 'absolute', top: 0, left: 90, width: '90%', height: '90%' }}
              frameBorder="0"
              allow="autoplay; fullscreen; clipboard-write; gyroscope; encrypted-media; accelerometer; picture-in-picture"
            />
          </div>
        )}
      </Box>
    </Page>
  );
};

export default Record;
