import { Typography, Box, useMediaQuery } from '@material-ui/core';
import React from 'react';
import Page from './Page';

const About = () => {
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Page title="stork about" canonical="https://www.storkcollective.com/about" fontFamily="Courier New">
      <Box display="flex" justifyContent="space-between">
        <Box width={mobile ? '100%' : '35%'} textAlign="justify">
          <Typography style={{ marginLeft: 0 }}>
            Είμαστε μια ομάδα ατόμων που δημιουργούν και/ή καταναλώνουν τέχνη.
            Λειτουργούμε αυτοοργανωμένα και πιστεύουμε στην ευθεία υποστήριξη των καλλιτεχνών. Βλέπουμε την τέχνη σαν τρόπο έκφρασης και όχι σαν εμπόρευμα. Στόχος μας είναι να δημιουργηθεί μία κοινότητα η οποία θα συζητάει, θα αλληλοβοηθιέται και θα εκφράζεται δημιουργικά και συνεργατικά. Το σχέδιο αυτό είναι σε καλό δρόμο.
<br />
          </Typography>
        </Box>
      </Box>
    </Page>
  );
};

export default About;
