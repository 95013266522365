import React, { Fragment } from 'react';
import { Switch, Router, Route } from 'react-router-dom';
import { StylesProvider, ThemeProvider } from '@material-ui/core';
import { createBrowserHistory } from 'history';

import MainLayout from './layout/MainLayout';
import { createTheme } from './theme';
import GlobalStyles from './components/GlobalStyles';

import Home from './views/Home';
import Film from './views/Film';
import Book from './views/Book';
import Record from './views/Record';
import About from './views/About';
import Contact from './views/Contact';

const history = createBrowserHistory();

const routes = [
  {
    exact: true,
    path: '/',
    layout: MainLayout,
    component: Home,
  },
  {
    exact: true,
    path: '/films',
    layout: MainLayout,
    component: Film,
  },
  {
    exact: true,
    path: '/books',
    layout: MainLayout,
    component: Book,
  },
  {
    exact: true,
    path: '/records',
    layout: MainLayout,
    component: Record,
  },
  {
    exact: true,
    path: '/about',
    layout: MainLayout,
    component: About,
  },
  {
    exact: true,
    path: '/contact',
    layout: MainLayout,
    component: Contact,
  },
];

const App = () => {
  const theme = createTheme();
  return (
    <ThemeProvider theme={theme}>
      <StylesProvider>
        <Router history={history}>
          <GlobalStyles />
          <Switch>
            {routes.map((route, i) => {
              const Layout = route.layout || Fragment;
              const Component = route.component;

              return (
                <Route
                  key={i}
                  path={route.path}
                  exact={route.exact}
                  render={(props) => (
                    <Layout>
                      <Component {...props} />
                    </Layout>
                  )}
                />
              );
            })}
          </Switch>
        </Router>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
