import { Grid, Container, Link, makeStyles } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer',
    },
  },
  pageContent: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 32,
    },
  },
}));
const Page = ({ title, canonical, navbar, selectedItem, fontFamily, children }) => {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={canonical} />
      </Helmet>
      <Container>
        <Grid container spacing={2}>
          {navbar && (
            <Grid item xs={12} sm={4}>
              {navbar.map((item) => (
                <Fragment key={item.text}>
                  <Link
                    style={{ fontFamily, fontWeight: selectedItem === item.text ? 'bold' : 'normal' }}
                    className={classes.link}
                    onClick={() => item.onClick(item.text)}
                    variant="h6"
                  >
                    {item.text}
                  </Link>
                  <br />
                </Fragment>
              ))}
            </Grid>
          )}
          <Grid className={classes.pageContent} item xs={12} sm={navbar ? 8 : 12}>
            {children}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Page;
