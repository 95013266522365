import React from 'react';
import { makeStyles } from '@material-ui/core';

import TopBar from '../components/TopBar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  wrapper: {
    paddingTop: 100,

    [theme.breakpoints.down('sm')]: {
      paddingTop: 32,
    },
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TopBar />
      <div className={classes.wrapper}>{children}</div>
    </div>
  );
};

export default MainLayout;
