import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Box, Container, Link, makeStyles, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 100,
  },
  link: {
    marginLeft: theme.spacing(3),
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer',
    },
  },
  firstLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer',
    },
  },
  activeLink: {
    fontWeight: 'bold',
  },
}));

const TopBar = () => {
  const classes = useStyles();
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const location = useLocation();

  return (
    <Container>
      {mobile ? (
        <Box className={classes.root} textAlign="center">
          <Link component={RouterLink} to="/" className={classes.firstLink} variant="h1">
            stork collective
          </Link>
          <Box display="flex" justifyContent="center" mt={3}>
            <Link
              component={RouterLink}
              to="/films"
              className={
                location.pathname === '/films' ? clsx(classes.firstLink, classes.activeLink) : classes.firstLink
              }
              variant="h5"
            >
              ταινίες
            </Link>
            <Link
              component={RouterLink}
              to="/records"
              className={location.pathname === '/records' ? clsx(classes.link, classes.activeLink) : classes.link}
              variant="h5"
            >
              μουσική
            </Link>
            <Link
              component={RouterLink}
              to="/books"
              className={location.pathname === '/books' ? clsx(classes.link, classes.activeLink) : classes.link}
              variant="h5"
            >
              βιβλία
            </Link>
            <Link
              component={RouterLink}
              to="/about"
              className={location.pathname === '/about' ? clsx(classes.link, classes.activeLink) : classes.link}
              variant="h5"
            >
              πληροφορίες
            </Link>
            <Link
              component={RouterLink}
              to="/contact"
              className={location.pathname === '/contact' ? clsx(classes.link, classes.activeLink) : classes.link}
              variant="h5"
            >
              επικοινωνία
            </Link>
          </Box>
        </Box>
      ) : (
        <Box className={classes.root} display="flex" alignItems="baseline">
          <Link component={RouterLink} to="/" className={classes.firstLink} variant="h1">
            stork collective
          </Link>
          <Box flexGrow={1} />
          <Link
            component={RouterLink}
            to="/films"
            className={location.pathname === '/films' ? clsx(classes.firstLink, classes.activeLink) : classes.firstLink}
            variant="h5"
          >
            ταινίες
          </Link>
          <Link
            component={RouterLink}
            to="/records"
            className={location.pathname === '/records' ? clsx(classes.link, classes.activeLink) : classes.link}
            variant="h5"
          >
            μουσική
          </Link>
          <Link
            component={RouterLink}
            to="/books"
            className={location.pathname === '/books' ? clsx(classes.link, classes.activeLink) : classes.link}
            variant="h5"
          >
            βιβλία
          </Link>
          <Link
            component={RouterLink}
            to="/about"
            className={location.pathname === '/about' ? clsx(classes.link, classes.activeLink) : classes.link}
            variant="h5"
          >
            πληροφορίες
          </Link>
          <Link
            component={RouterLink}
            to="/contact"
            className={location.pathname === '/contact' ? clsx(classes.link, classes.activeLink) : classes.link}
            variant="h5"
          >
            επικοινωνία
          </Link>
        </Box>
      )}
    </Container>
  );
};

export default TopBar;
